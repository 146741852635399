/* eslint-disable no-unused-vars */
import config from '../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {} from '@polymer/polymer/lib/elements/dom-repeat';
import {} from '@polymer/polymer/lib/elements/array-selector';
import {} from '@polymer/polymer/lib/mixins/mutable-data';

/* Astro Components */
import {RuxButton} from '@astrouxds/rux-button/rux-button.js';
import {RuxClock} from '@astrouxds/rux-clock/rux-clock.js';
import {RuxGlobalStatusBar} from '@astrouxds/rux-global-status-bar/rux-global-status-bar.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';

/* GRM Services Templates and Styles */
import {AppMenu} from './app-menu.js';
import {StatusIndicators} from './status/status-indicators';
import {EquipmentTree} from './tree/equipment-tree.js';
import {Overview} from './overview/overview.js';
import {EquipmentTab} from './equipment-tab/equipment-tab.js';
import {Maintenance} from './maintenance/maintenance.js';
import {RuxLog} from './log/rux-log.js';
import {RuxTabs} from './tabs/rux-tabs.js';

import template from './astro-app.html';
import css from './astro-app.css';
import astroCSS from './astro-css.js';
const utils = require('./common-utils.js');
/* eslint-enable no-unused-vars */

const EquipmentTaxonomy = require('./equipment-taxonomy.js');

/*
    GRM Equipment Manager Components
    Add GRM Equipment Manager specific components here
*/

/**
 * @polymer
 * @extends HTMLElement
 */
export class AstroApp extends PolymerElement {
  static get properties() {
    return {
      dynamicTabs: {
        type: Array,
        value: [
          {
            label: 'Inoperable',
            selected: true,
            targetTab: 'overview-tab',
            id: 'overview',
          },
        ],
      },
      statusData: {
        type: Object,
        value: {
          commsStatus: {worstStatus: 'standby', numMessages: 22},
          digitalStatus: {worstStatus: 'caution', numMessages: 35},
          facilitiesStatus: {worstStatus: 'standby', numMessages: 28},
          rfStatus: {worstStatus: 'off', numMessages: 27},
          softwareStatus: {worstStatus: 'caution', numMessages: 2},
          ucaStatus: {worstStatus: 'standby', numMessages: 45},
          timestamp: 1546641719633,
        },
      },
    };
  }

  /*
   *
   * Handle Dynamic Tab Creation
   */

  _selectEquipment(equipment) {
    const _tabExists = this.dynamicTabs.find((tab) => tab.id === equipment.detail.id);
    const _tabIndex = this.dynamicTabs.findIndex((tab) => tab.id === equipment.detail.id);

    if (_tabExists) {
      this.set(`dynamicTabs.${_tabIndex}.selected`, true);
      this.notifyPath(`dynamicTabs.${_tabIndex}`);
      return;

      // For the demo artifically limit the number of tabs to 5 dynamically created
    } else if (this.dynamicTabs && this.dynamicTabs.length >= 6) {
      alert('This demo constrains the number of tabs to 5. This is not a recommended UX pattern.');
      return;
    } else {
      this.addTab(equipment.detail);
    }
  }

  _toggleSelection(e) {
    const _target = e.detail.tab;

    this.$.selector.select(_target);

    this.set('treeData.selected', e.detail.tab);
  }

  // add a tab to the dynamic tab array
  addTab(newTab) {
    if (!newTab) return;

    // @todo look for a cleaner way to assign object props
    newTab.dynamic = true;
    newTab.selected = true;
    newTab.targetTab = 'dynamic-tab';
    this.push('dynamicTabs', newTab);
  }

  viewJobDetails(e) {
    this.set('jobDetail', e.detail.job);
  }
  createJob() {
    this.set('jobDetail', this.emptyJobDetail);
    this.editJobMode = true;
  }
  exitJobDetails() {
    this.editJobMode = false;
    this.set('jobDetail', null);
  }

  saveJobDetails(e) {
    this.editJobMode = false;
    this.set('jobDetail', null);
    this.displayNotification(e);
  }

  displayNotification(e) {
    this.notificationMessage = e.detail.message;
    this.notificationOpen = true;
    window.setTimeout(() => {
      this.notificationOpen = false;
    }, this.notificationTimeout * 1000);
  }

  /*
   *
   * Start Data Services
   */
  _startStatusIndicatorsWebsocket() {
    const ws = new WebSocket(config.dataServers.statusIndicators);
    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.statusData = payload;
    });
  }

  /*
   *
   * Handle App Switcher
   * @todo These functions would be well suited for a shared function
   *       between the apps
   */
  displayPopUpMenu(e) {
    if (e.detail.menu) {
      this.appMenuTarget = e.detail.target;
      this.menuItems = e.detail.menu;
    } else {
      this.appMenuTarget = e.currentTarget;
      this.menuItems = config.menuItems;
    }
  }

  constructor() {
    super();

    this._registerSelectTabEvent = this._toggleSelection.bind(this);
    this._registerSelectEquipmentEvent = this._selectEquipment.bind(this);

    this.menuItems = config.menuItems;
    this.user = config.user;

    this.notificationMessage = '';
    this.notificationOpen = false;
    this.notificationTimeout = 8;

    this.detailPaneEditMode = true;

    this.emptyJobDetail = {
      id: null,
      type: null,
      description: '',
      startTime: null,
      endTime: null,
      technician: '',
      isFollowing: false,
    };

    this.jobDetail = null;
    this.editJobMode = false;

    EquipmentTaxonomy.forEach((grandparent, index) => {
      const _grandparentIndex = index;
      grandparent.children.forEach((parent, index) => {
        const _parentIndex = index;
        parent.children.forEach((child, index) => {
          child.meta = {
            tree: [_grandparentIndex, _parentIndex, index],
          };
        });
      });
    });

    this.treeData = EquipmentTaxonomy;
  }

  connectedCallback() {
    super.connectedCallback();
    this._startStatusIndicatorsWebsocket();
    window.addEventListener('close-tab', this._registerCloseTabEvent);
    window.addEventListener('select-tab', this._registerSelectTabEvent);
    window.addEventListener('select-tree-item', this._registerSelectEquipmentEvent);
    this.addEventListener('createNewContact', this._beginNewContactFlow);
    this.addEventListener('createJob', this.createJob);
    this.addEventListener('viewJobDetails', this.viewJobDetails);
    this.addEventListener('exitJobDetails', this.exitJobDetails);
    this.addEventListener('saveJobDetails', this.saveJobDetails);
    this.addEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('close-tab', this._registerCloseTabEvent);
    window.removeEventListener('select-tab', this._registerSelectTabEvent);
    this.removeEventListener('createJob', this.createJob);
    this.removeEventListener('viewJobDetails', this.viewJobDetails);
    this.removeEventListener('exitJobDetails', this.exitJobDetails);
    this.removeEventListener('saveJobDetails', this.saveJobDetails);
    this.removeEventListener('showPopUpMenu', this.displayPopUpMenu);
  }
  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
        ${css}
        </style> 
        ${template}`,
    ]);
  }
}
customElements.define('astro-app', AstroApp);
