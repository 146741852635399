import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* eslint-disable no-unused-vars */
import {RuxSegmentedButton} from '@astrouxds/rux-segmented-button/rux-segmented-button.js';
import {GrmAlerts} from './alerts/alerts.js';
import {CurrentContacts} from './contacts/current-contacts.js';
import {RuxNotification} from '@astrouxds/rux-notification/rux-notification.js';

/* eslint-enable no-unused-vars */

import template from './equipment-tab.html';
import css from './equipment-tab.css';
const utils = require('../common-utils.js');

/**
 * @polymer
 * @extends HTMLElement
 */
export class EquipmentTab extends PolymerElement {
  static get properties() {
    return {
      notificationMessage: {
        type: String,
      },
      notificationOpen: {
        type: Boolean,
      },
      selectedEquipment: {
        type: Object,
      },
      alertsLog: {
        type: Array,
        value: [
          {
            errorId: '6d76630e-e99f-5615-9bd8-331a0fc4b955',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 121 - Offline',
            longMessage: 'Red FEP 121 is offline at 18:37:45',
            errorTime: 1572134265725,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '20a96646-abbc-5195-9b20-cff2e99f2ada',
            errorSeverity: 'critical',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-168 - Power degradation',
            longMessage: 'USA-168 suffered power degradation at 18:37:54',
            errorTime: 1572134274738,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'e7d304c2-17ef-5426-ac70-4431fa580409',
            errorSeverity: 'critical',
            errorCategory: 'software',
            errorMessage: 'Black FEP 121 - Degraded',
            longMessage: 'Black FEP 121 is degraded at 18:37:57',
            errorTime: 1572134277742,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '47e8c77b-227e-5606-8718-7fafb67f8f8b',
            errorSeverity: 'caution',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-150 - Solar panel misalignment',
            longMessage: 'USA-150 experienced solar panel misalignment at 18:38:00',
            errorTime: 1572134280747,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '55282589-4b4b-5ac2-b6c9-97b0bf06dfad',
            errorSeverity: 'critical',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 1 - Offline',
            longMessage: 'Antenna DGS 1 went offline at 18:38:09',
            errorTime: 1572134289757,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'f35ff6e5-ea12-572b-8a27-ecae8b716ae2',
            errorSeverity: 'serious',
            errorCategory: 'software',
            errorMessage: 'Red FEP 201 - Degraded',
            longMessage: 'Red FEP 201 is degraded at 18:38:16',
            errorTime: 1572134296767,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '5ca28dca-fbd2-5328-9123-7dfa1a5f0ad9',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 301 - Degraded',
            longMessage: 'Red FEP 301 is degraded at 18:38:17',
            errorTime: 1572134297768,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '576d94af-206a-5281-a86a-027ee8d13ec3',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 2 - Offline',
            longMessage: 'Antenna DGS 2 went offline at 18:38:23',
            errorTime: 1572134303776,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'de220699-9b65-5eea-ab06-a1eeb5bd5e23',
            errorSeverity: 'serious',
            errorCategory: 'hardware',
            errorMessage: 'Workstation 134 - Offline',
            longMessage: 'Workstation 134 is offline at 18:38:24',
            errorTime: 1572134304777,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'c9c1060c-03f5-5669-b0d4-c2f89ba9d0e1',
            errorSeverity: 'critical',
            errorCategory: 'software',
            errorMessage: 'Black FEP 121 - Offline',
            longMessage: 'Black FEP 121 is offline at 18:38:27',
            errorTime: 1572134307782,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '53334558-f2ff-5def-89a4-2e1aacda6e3d',
            errorSeverity: 'critical',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-177 - Solar panel misalignment',
            longMessage: 'USA-177 experienced solar panel misalignment at 18:38:32',
            errorTime: 1572134312791,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '9615b023-e556-592f-8358-01775dd125f7',
            errorSeverity: 'critical',
            errorCategory: 'hardware',
            errorMessage: 'Antenna DGS 2 - Weak signal',
            longMessage: 'Antenna DGS 2 has weak signal at 18:38:37',
            errorTime: 1572134317799,
            selected: false,
            new: false,
            expanded: false,
          },
        ],
      },
      contactsLog: {
        type: Array,
        value: function() {
          return [
            {
              contactId: '80e5654b-df70-5a9c-85dd-75541a7cafae',
              contactName: '22683',
              contactGround: 'HTS',
              contactEquipment: 'ANT43 VAFB1 SFEP227CH1 ECEU6 WS402 USP177',
              contactState: 'executing',
              contactStep: 'Downlink',
              contactDetail:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              contactBeginTimestamp: 1571086435343,
              contactEndTimestamp: 1571088368326,
              expanded: false,
            },
            {
              contactId: '40e5654b-df70-5a9c-85dd-75541a7cafae',
              contactName: '68112',
              contactGround: 'VTS',
              contactEquipment: 'ANT74 BAFB1 SFEP299CH1 ECEU6 WS305 USP451',
              contactState: 'failed',
              contactStep: 'AOS',
              contactDetail:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              contactBeginTimestamp: 1571088235343,
              contactEndTimestamp: 1571088398326,
              expanded: false,
            },
            {
              contactId: '70e5654b-df70-5a9c-85dd-75541a7cafae',
              contactName: '16834',
              contactGround: 'DGS',
              contactEquipment: 'ANT73 PAFB1 SFEP149CH1 ECEU6 WS167 USP182',
              contactState: 'executing',
              contactStep: 'Uplink',
              contactDetail:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              contactBeginTimestamp: 1571088635343,
              contactEndTimestamp: 1571088768326,
              expanded: false,
            },
            {
              contactId: '00e5654b-df70-5a9c-85dd-75541a7cafae',
              contactName: '62346',
              contactGround: 'TCS',
              contactEquipment: 'ANT52 SAFB1 SFEP374CH1 ECEU6 WS481 USP342',
              contactState: 'executing',
              contactStep: 'Downlink',
              contactDetail:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              contactBeginTimestamp: 1571085435343,
              contactEndTimestamp: 1571089368326,
              expanded: false,
            },
          ];
        },
      },
      onlineState: {
        type: Array,
        value: [{label: 'Online', value: true, selected: true}, {label: 'Offline', value: false}],
      },
      consideredState: {
        type: Array,
        value: [{label: 'Considered', value: true, selected: true}, {label: 'Deconsidered', value: false}],
      },
    };
  }

  /* Define component-specific methods */

  isAlertVisible(status, category, alert) {
    if (status !== 'all' && status !== alert.errorSeverity) return false;
    if (category !== 'all' && category !== alert.errorCategory) return false;
    return true;
  }

  removeItemsFromAlertsLog(event) {
    const alertsToKeep = this.alertsLog.filter((alert) => {
      const isVisible = this.isAlertVisible(event.detail.activeStatusFilter, event.detail.activeCategoryFilter, alert);
      // Prevent previously-selected but filtered-out items from being removed
      return !isVisible || !alert.selected;
    });

    this.set('alertsLog', alertsToKeep);
  }

  setAlertSelectedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'selected'], event.detail.setSelectedTo);
        this.notifyPath(['alertsLog', index, 'selected']);
      }
    });
  }

  setAlertExpandedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'expanded'], event.detail.setExpandedTo);
        this.notifyPath(['alertsLog', index, 'expanded']);
      }
    });
  }

  setContactExpandedState(event) {
    this.contactsLog.forEach((contact, index) => {
      if (contact.contactId === event.detail.contactId) {
        this.set(['contactsLog', index, 'expanded'], event.detail.setExpandedTo);
        this.notifyPath(['contactsLog', index, 'expanded']);
      }
    });
  }

  updateLog() {
    this.unshift('equipmentLogData', {
      timestamp: new Date(),
      status: this.logStatuses[Math.floor(Math.random() * this.logStatuses.length)],
      entry: this.logMessages[Math.floor(Math.random() * this.logMessages.length)],
    });
  }

  continuallyUpdateLog(count) {
    this.updateLog();
    if (count > 0) {
      window.setTimeout(() => {
        this.continuallyUpdateLog(count--);
      }, 3000);
    }
  }

  viewJobDetails(event) {
    const jobDetails = event.model.job;
    jobDetails.equipment = this.selectedEquipment.label || 'Missing Equipment Label';
    this.dispatchEvent(
        new CustomEvent('viewJobDetails', {
          detail: {
            job: jobDetails,
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  createJob() {
    this.dispatchEvent(
        new CustomEvent('createJob', {
          detail: {},
          bubbles: true,
          composed: true,
        })
    );
  }

  constructor() {
    super();
    /* Register utility methods */
    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
    this.formatDayOfYear = utils.formatDayOfYear;
    this.formatYear = utils.formatYear;
    this.mapJobType = utils.mapJobType;
    this.mapJobProgress = utils.mapJobProgress;
    this.logStatuses = utils.logStatuses;
    this.logMessages = utils.logMessages;
    this.equipmentLogData = [];

    /* Create sample data with Lorem Ipsum placeholder text */
    const loremIpsum = function() {
      return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mattis dolor efficitur nisl sagittis, rutrum ornare mauris consectetur. Nunc at sem quam. Etiam dolor mauris, dictum sed condimentum sit amet, interdum eget dolor. Nullam quis fringilla ex, non scelerisque mauris. Phasellus aliquam ligula sapien, et vulputate lorem mollis vitae.';
    };

    this.sampleEquipmentData = {
      description: loremIpsum(),
      maintenanceJobs: [
        {
          id: '00006',
          type: 1,
          createdTime: 1572141252364,
          startTime: 1572134265725,
          endTime: 1572134912791,
          progressStep: 2,
          description: loremIpsum(),
          technician: 'Ahmet Ducat',
          isFollowing: true,
        },
        {
          id: '00007',
          type: 2,
          createdTime: 1572141252364,
          startTime: 1572144265725,
          endTime: 1572148912791,
          progressStep: 3,
          description: loremIpsum(),
          technician: 'Cristofer Sandoval',
          isFollowing: true,
        },
        {
          id: '00008',
          type: 1,
          createdTime: 1572141252364,
          startTime: 1572134265725,
          endTime: 1572134912791,
          progressStep: 2,
          description: loremIpsum(),
          technician: 'Andie Spatzig',
          isFollowing: true,
        },
        {
          id: '00009',
          type: 2,
          createdTime: 1582141252364,
          startTime: 1582144265725,
          endTime: 1582148912791,
          progressStep: 3,
          description: loremIpsum(),
          technician: 'Ahmet Ducat',
          isFollowing: true,
        },
        {
          id: '00010',
          type: 2,
          createdTime: 1582141252364,
          startTime: 1582144265725,
          endTime: 1582148912791,
          progressStep: 3,
          description: loremIpsum(),
          technician: 'Lara Pazzi',
          isFollowing: true,
        },
      ],
      maintenanceLog: [
        {
          id: '00001',
          type: 1,
          createdTime: 1572141252364,
          startTime: 1572134265725,
          endTime: 1572134912791,
          progressStep: 2,
          description: loremIpsum(),
          technician: 'Ahmet Ducat',
          isFollowing: true,
        },
        {
          id: '00002',
          type: 2,
          createdTime: 1572141252364,
          startTime: 1572144265725,
          endTime: 1572148912791,
          progressStep: 3,
          description: loremIpsum(),
          technician: 'Ahmet Ducat',
          isFollowing: true,
        },
        {
          id: '00003',
          type: 1,
          createdTime: 1572141252364,
          startTime: 1572134265725,
          endTime: 1572134912791,
          progressStep: 2,
          description: loremIpsum(),
          technician: 'Cristofer Sandoval',
          isFollowing: true,
        },
        {
          id: '00004',
          type: 2,
          createdTime: 1572141252364,
          startTime: 1572144265725,
          endTime: 1572148912791,
          progressStep: 3,
          description: loremIpsum(),
          technician: 'Lara Pazzi',
          isFollowing: true,
        },
        {
          id: '00005',
          type: 2,
          createdTime: 1572141252364,
          startTime: 1572144265725,
          endTime: 1572148912791,
          progressStep: 3,
          description: loremIpsum(),
          technician: 'Ahmet Ducat',
          isFollowing: true,
        },
      ],
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('removeAlerts', this.removeItemsFromAlertsLog);
    this.addEventListener('alertSelectionChanged', this.setAlertSelectedState);
    this.addEventListener('alertToggleChanged', this.setAlertExpandedState);
    this.addEventListener('contactToggleChanged', this.setContactExpandedState);
    this.continuallyUpdateLog(100);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include='astro-css'>
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('equipment-tab', EquipmentTab);
