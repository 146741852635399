/* eslint-disable no-unused-vars */
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import {RuxTab} from './rux-tab.js';
import {RuxTabPanels} from './rux-tab-panels.js';
import {RuxTabPanel} from './rux-tab-panel.js';

import template from './rux-tabs.html';
import css from './rux-tabs.css';
/* eslint-enable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTabs extends PolymerElement {
  static get is() {
    return 'rux-tabs';
  }
  static get properties() {
    return {
      tabs: {
        type: Array,
        notify: true,
      },
      selectedTabIndex: {
        type: Number,
        observer: '_setTabIndex',
      },
      selectedTab: {
        type: Object,
        notify: true,
        observer: '_setTab',
      },
      _panels: {
        type: Object,
      },
      _panelGroup: {
        type: String,
      },
      compact: {
        type: Boolean,
      },
      transparent: {
        type: Boolean,
      },
    };
  }

  static get observers() {
    return ['_tabsChanged(tabs.*)'];
  }

  static get template() {
    return html([
      `
      <style include="astro-css">
        ${css}
      </style> 
      ${template}`,
    ]);
  }

  constructor() {
    super();

    this._registerPanelsListener = this._registerPanels.bind(this);
    this._registerAddTabEvent = this._addTab.bind(this);
    this._registerCloseTabEvent = this._closeTab.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this._panels = null;

    // listener events for child elements
    window.addEventListener('register-panels', this._registerPanelsListener);
    window.addEventListener('add-tab', this._registerAddTabEvent);
    window.addEventListener('close-tab', this._registerCloseTabEvent);

    // listener events for DOM
    this.addEventListener('click', this._onClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    // deregister listener events for child elements
    window.removeEventListener('register-panels', this._registerPanelsListener);
    window.removeEventListener('add-tab', this._registerAddTabEvent);
    window.removeEventListener('close-tab', this._registerTabAddedListener);

    // deregister events for local
    this.removeEventListener('click', this._onClick);
  }

  ready() {
    super.ready();
  }

  _setTabIndex(e) {
    this.selectedTab = this.tabs[e];
  }

  _tabsChanged(e) {
    if (e.value && e.value.selected) {
      this.selectedTabIndex = this.tabs.findIndex((tab) => {
        return tab.id === e.value.id;
      });
    }
  }

  _onClick(e) {
    // fire click event if the target is a tab and it hasn’t been disabled
    if (e.target.getAttribute('role') === 'tab' && e.target.getAttribute('disabled') === null) {
      this.selectedTabIndex = this.tabs.findIndex((tab) => {
        return tab.id === e.target.getAttribute('id');
      });
    }
  }

  /*
   * When a new tab is added it should become the newly
   * selected tab. The selected state is set at the property
   * level when the tab is added to the tabs array
   * */
  _addTab(e) {
    if (e.detail.tab.selected) {
      this.selectedTabIndex = this.tabs.length - 1;
    }
  }

  /*
   * In this custom implementation of tabs, there are only two
   * with the second tab being one that populates data fields
   * rather than switching DOM elements like the original tabs
   * component does.
   *
   * Panels are registered as they are added to the DOM unlike
   * */
  _registerPanels(e) {
    if (e.detail.for === this.getAttribute('id')) {
      this._panels = Array.from(e.detail.panels);
    }
  }

  /*
   * Resets all tabs and panels to unselected/hidden state
   * @todo figure out a more elegant data sharing model that
   * doens't require notifyPath
   */

  _reset() {
    this.tabs.forEach((tab, index) => {
      this.set(`tabs.${index}.selected`, false);

      // this is only required when selecting a tab from outside
      // of the tab component.
      this.notifyPath(`tabs.${index}`);
    });

    if (this._panels && this._panels.length) {
      this._panels.forEach((panel) => panel.classList.add('hidden'));
    }
  }

  /*
   * Close Tab event is fired from tab.js. This function removes
   * the tab from the tabs array and determines which tab should
   * receive focus.
   *
   * If the closed tab is selected and is the last tab, the previous
   * tab should  be selected.
   *
   * If the closed tab is selected and is not the last tab, the next
   * next tab should be selected
   *
   * If the closed tab is NOT selected no change to tab selection will
   * occur
   */
  _closeTab(e) {
    // find the tab to be replaced
    let tabIndex = this.tabs.findIndex((tab) => tab.id === e.detail.tab.id);

    // remove from the tabs array using Polymer’s array mutation
    // method so it notifies of the change
    this.splice('tabs', tabIndex, 1);

    // set tab index manually if the last tab was removed
    if (tabIndex > this.tabs.length - 2) {
      tabIndex = this.tabs.length - 1;
    }

    // notify the app of a selected tab change if
    if (e.detail.tab.selected) {
      this.selectedTabIndex = tabIndex;
      this.selectedTab = this.tabs[this.selectedTabIndex];
    }
  }

  /*
   * Handles setting the selected tab state, notifying the appropriate
   * array, selecting the correct panel and emitting an event to the
   * app to handle populating the data
   */
  _setTab() {
    if (this.selectedTab === null) return;

    this._reset();
    this.set(`tabs.${this.selectedTabIndex}.selected`, true);

    const selectedPanel = this._panels.find((panel) => {
      return panel.getAttribute('aria-labeledby') === this.selectedTab.targetTab;
    });

    selectedPanel.classList.remove('hidden');

    window.dispatchEvent(
        new CustomEvent('select-tab', {
          detail: {
            tab: this.selectedTab,
          },
        })
    );
  }
}

customElements.define('rux-tabs', RuxTabs);
