/* eslint-disable no-unused-vars */
import {PolymerElement} from '@polymer/polymer/polymer-element.js';
/* eslint-enable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTabPanels extends PolymerElement {
  static get properties() {
    return {
      type: String,
    };
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'tablist');

    const _panels = this.querySelectorAll('rux-tab-panel');

    window.dispatchEvent(
        new CustomEvent('register-panels', {
          detail: {panels: _panels, for: this.getAttribute('aria-labeledby')},
        })
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }
}

customElements.define('rux-tab-panels', RuxTabPanels);
