import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
/* eslint-enable no-unused-vars */

import template from './alert.html';
import css from './alert.css';
const utils = require('../../common-utils.js');


/**
 * @polymer
 * @extends HTMLElement
 */
export class GrmAlert extends PolymerElement {
  static get properties() {
    return {
      selected: {
        type: Boolean,
        value: true,
      },
      new: {
        type: Boolean,
        value: true,
      },
      animationClass: {
        type: String,
        computed: 'computeAnimationClass(new)',
      },
      toggleClass: {
        type: String,
        computed: 'computeToggleClass(expanded)',
      },
      id: {
        type: String,
        value: 'ID missing',
      },
      severity: {
        type: String,
        value: '',
      },
      category: {
        type: String,
        value: '',
      },
      message: {
        type: String,
        value: 'No Error Message',
      },
      detail: {
        type: String,
        value: 'No Error Message',
      },
      time: {
        type: Number,
      },
      expanded: {
        type: Boolean,
        value: false,
      },
    };
  }

  /* Define component-specific methods */

  computeAnimationClass() {
    return this.new ? 'animation-active' : '';
  }
  computeToggleClass() {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  notifyClick(e) {
    this.dispatchEvent(
        new CustomEvent('alertSelectionChanged', {
          detail: {
            errorId: this.id,
            setSelectedTo: !this.selected,
          },
          bubbles: true,
          composed: true,
        })
    );
    e.stopPropagation();
    return false;
  }

  toggleExpand() {
    this.dispatchEvent(
        new CustomEvent('alertToggleChanged', {
          detail: {
            errorId: this.id,
            setExpandedTo: !this.expanded,
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  constructor() {
    super();
    /* Register utility methods */
    this.capitalize = utils.capitalize;
    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      `<style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('grm-alert', GrmAlert);
