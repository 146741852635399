/* eslint-disable no-unused-vars */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';

import template from './equipment-tree.html';
import css from './equipment-tree.css';
/* eslint-enable no-unused-vars */
/**
 * @polymer
 * @extends HTMLElement
 */
export class EquipmentTree extends PolymerElement {
  static get properties() {
    return {
      data: {
        type: Object,
        notify: true,
      },
    };
  }

  static get observers() {
    return ['_selectedTreeItemUpdated(data.selected)'];
  }
  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  _selectedTreeItemUpdated(item) {
    if (!this._me) {
      this._me = item;
      return;
    } else {
      if (this._me.id !== item.id) {
        this._reset();

        if (item.meta) {
          this.notifyPath(
              `data.${item.meta.tree[0]}.children.${item.meta.tree[1]}.children.${item.meta.tree[2]}.selected`,
              true
          );
        }
        this._me = item;
      }
    }
  }

  _getSelected(item) {
    return item ? 'selected' : '';
  }

  _getExpanded(item) {
    return item ? 'expanded' : '';
  }

  _hasChildren(item) {
    return item && item.length ? 'has-children' : 'no-children';
  }

  _reset() {
    if (this._me) {
      this._me.selected = false;

      // basically to avoid the overview tab firing an error
      if (this._me.meta) {
        this.notifyPath(
            `data.${this._me.meta.tree[0]}.children.${this._me.meta.tree[1]}.children.${this._me.meta.tree[2]}.selected`,
            false
        );
      }
    }
  }

  _select(event) {
    event.preventDefault();

    this.set('data.selected', event.model.item);
    event.model.set('item.selected', true);

    window.dispatchEvent(
        new CustomEvent('select-tree-item', {
          detail: event.model.item,
        })
    );
  }

  _expand(event) {
    event.model.set('item.expanded', event.model.item.expanded ? false : true);
  }
}
customElements.define('equipment-tree', EquipmentTree);
