import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import {afterNextRender} from '@polymer/polymer/lib/utils/render-status.js';

// import rux-status
/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxLog extends PolymerElement {
  static get properties() {
    return {
      data: {
        type: Object,
      },
      formatting: {
        type: Object,
      },
      maxLines: {
        type: Number,
        value: 10,
      },
      timezone: {
        type: String,
        value: 'UTC',
      },
      renderedItemCount: {
        type: Number,
        observer: '_getVisibleItems',
      },
      _visibleItems: {
        type: Number,
      },
      _filterValue: {
        type: String,
        value: null,
      },
      _height: {
        type: Number,
      },
      log: {
        type: Array,
        value: function() {
          return [];
        },
      },
    };
  }

  static get template() {
    return html`

    <style>

      :host {
        display: block;
        font-size: 0.875rem;
        color: var(--fontColor, rgb(255,255,255));
        background-color: var(--logBackgroundColor, rgb(30, 47, 66));
      }

      ::-webkit-scrollbar {
        width: 18px;
        height: 18px;
        background-color: transparent;
      }
      
      ::-webkit-scrollbar-thumb {
        background-color: rgb(46, 103, 153);
        background-color: var(--scrollBarThumbBackgroundColor, rgb(46, 103, 153));
        border-radius: 10px;
        border: 3px solid transparent;
        background-clip: padding-box;
      }
      /* visually "centers" because the dark edge of the shadow gives the illusion this is offset */
      ::-webkit-scrollbar-thumb:vertical {
        border-left-width: 4px;
      }
      
      ::-webkit-scrollbar-thumb:horizontal {
        border-top-width: 4px;
      }
      
      ::-webkit-scrollbar-thumb:active,
      ::-webkit-scrollbar-thumb:hover {
        background-color: rgb(58, 129, 191)
        ;
        background-color: var(
          --scrollBarThumbBackgroundHoverColor,
          rgb(58, 129, 191)
        );
      }
      
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-corner {
        background-color: rgb(32, 50, 70);
        background-color: var(--scrollBarTrackCornerBackgroundColor, rgb(32, 50, 70));
      }
      ::-webkit-scrollbar-track:vertical {
        box-shadow: inset 2px 0 4px rgba(0, 0, 0, 0.15);
      }
      ::-webkit-scrollbar-track:horizontal {
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
      }

      *[hidden] {
        display: none !important;
      }
      
      .rux-log-header {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;
      
        padding: 0.5rem;
        
      }
      
      .rux-log-header-title {
        margin: 0 0 1rem 0;
        display: none;
      
        font-size: 1.25rem;
        font-weight: 300;
      }
      
      .rux-log__header-labels {
        display: flex;
        width: 100%;
        color: var(--logHeaderTextColor, rgb(255, 255, 255));
      }
      
      .rux-log__header-labels,
      .rux-log__events {
        padding: 0;
        margin: 0;
        list-style: none;
      }
      
      .rux-log__header-labels,
      .rux-log__log-event {
        display: flex;
        align-content: flex-start;
      }
      
      .rux-log__events {
        height: 100%;
        overflow-y: scroll;
        background-color: var(--logHeaderBackgroundColor, rgb(20, 32, 44));
      }
      
      .log-event__timestamp {
        flex-shrink: 0;
      
        text-align: right;
        width: 5rem;
      }
      
      
      
      .rux-log__log-event {
        display: flex;
        flex-shrink: 0;
        align-items: flex-start;
        padding: 0.5rem 0;
        border-bottom: 1px solid var(--logBorderColor, rgb(40, 63, 88));
      }
      
      .rux-log__log-event:last-child {
        border-bottom: none;
      }
      
      .rux-log__header-labels li:not(:first-child),
      .rux-log__log-event > * {
        margin: 0 0.5rem;
      }
      
      .rux-log__header-labels li:first-child {
        margin: 0 0.5rem 0 0;
      }
      
      .rux-log__log-event .log-event__timestamp {
        font-family: var(--fontFamilyMono, "Roboto Mono", monospace);
      }
      
      .log-event__status {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        width: 1rem;
        overflow: hidden;
      }
      
      .log-event__message {
        flex-grow: 1;
        text-align: left;
      }
      
      .log-header__message {
        display: flex;
        justify-content: space-between;
      }
      
      /* ol li:nth-child(even) {
        background-color: #283f58;
      } */
      
      .rux-log__filter-enabled {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
      
        align-content: center;
      
        color: var(--logFilterTextColor, rgb(0, 0, 0));
        background-color: var(--logFilterBackgroundColor, rgb(192, 240, 255));
        padding: 0.5rem;
      }
      
      .rux-log__filter-enabled rux-icon {
        margin-right: 0.5rem;
      }
      
      input[type="search"] {
        border: none;
        border-radius: 3px;
        font-size: 0.875rem;
        padding: 0.25rem 0.25rem 0.25rem 1.75rem;
        background: no-repeat center left 0.3rem/1.1em
        url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22128%22%20height%3D%22128%22%20viewBox%3D%220%200%20128%20128%22%3E%0A%20%20%3Cpath%20fill%3D%22%231c7dc6%22%20fill-rule%3D%22evenodd%22%20d%3D%22M65.2499813%2C70.1398459%20L66.0444022%2C70.9342669%20L54.7988583%2C82.1798107%20L56.3043771%2C83.6853295%20L16.6853295%2C123.304377%20L2.67788086%2C109.296928%20L42.2969285%2C69.6778809%20L44.1340519%2C71.5150043%20L55.2606744%2C60.3883817%20C51.1630812%2C54.4800618%2048.7619048%2C47.3061576%2048.7619048%2C39.5714286%20C48.7619048%2C19.3735863%2065.1354911%2C3%2085.3333333%2C3%20C105.531176%2C3%20121.904762%2C19.3735863%20121.904762%2C39.5714286%20C121.904762%2C59.7692708%20105.531176%2C76.1428571%2085.3333333%2C76.1428571%20C77.9159615%2C76.1428571%2071.0143372%2C73.9346798%2065.2499813%2C70.1398459%20Z%20M85.3333333%2C67%20C100.481715%2C67%20112.761905%2C54.7198103%20112.761905%2C39.5714286%20C112.761905%2C24.4230469%20100.481715%2C12.1428571%2085.3333333%2C12.1428571%20C70.1849516%2C12.1428571%2057.9047619%2C24.4230469%2057.9047619%2C39.5714286%20C57.9047619%2C54.7198103%2070.1849516%2C67%2085.3333333%2C67%20Z%22%2F%3E%0A%3C%2Fsvg%3E")
        rgb(255, 255, 255);
      }
      

    </style>

  
  <header class="rux-log-header">
    <h1 class="rux-log-header-title">Event Logs</h1>
    <ul class="rux-log__header-labels rux-row">
      <li class="log-event__timestamp">Time</li>
      <li class="log-event__status"></li>
      <li class="log-event__message log-header__message">Event
      <div class="rux-form-field rux-form-field--small">
        <input class="rux-form-field__text-input" placeholder="Filter Log …" type="search" value={{_filterValue::input}}></li>
        
      </div>
      
    </ul>
  </header>

  <!-- 
  Renable when a proper method for determing height/width can be determined
  <ol class="rux-log__events" style$="max-height: [[_height]]px"> 
  //-->
  <ol class="rux-log__events">
    
    <li class="rux-log__filter-enabled" hidden=[[!_filterValue]]>
    A filter with&nbsp;<b>[[_filterValue]]</b>&nbsp;is enabled. [[_visibleItems]] of [[log.length]] records are currently hidden.
    </li>
    
    <template is="dom-repeat" id="rux-log-data" items={{log}} filter="{{_filter(_filterValue)}}" rendered-item-count="{{renderedItemCount::dom-change}}" notify-dom-change>
      <li class="rux-log__log-event">
        <div class="log-event__timestamp">
          <time datetime$=[[_formatMachineTime(item.timestamp)]]>[[_formatReadableTime(item.timestamp)]]</time>
        </div>
        <div class="log-event__status">
          <rux-status status=[[item.status]]></rux-status>
        </div>
        <div class="log-event__message">
          <div>[[item.entry]]</div>
        </div>
      </li>
    </template>
    
  </ol>
  `;
  }
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    this.data = {
      timestamp: new Date(),
      status: '',
      entry: 'Logging inited dol …',
    };

    // set the max height for the
    afterNextRender(this, () => {
      const logLine = this.shadowRoot.querySelector('.rux-log__log-event');
      this._height = logLine.offsetHeight * this.maxLines;
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  _filter(filterValue) {
    // don't run the filter if there is no search param
    if (!filterValue) return null;

    // returns the log entry if it includes the search term
    // NOTE: using String.prototype.includes which is unavailable
    // in IE11
    return (log) => {
      return log.entry.toLowerCase().includes(filterValue.toLowerCase()) ? true : false;
    };
  }

  _getVisibleItems() {
    if (this.log.length && this.renderedItemCount > -1) {
      this._visibleItems = this.log.length - this.renderedItemCount;
    }
  }

  // return an HTML5 datetime string
  _formatMachineTime(time) {
    const utc = `${time.getUTCFullYear()}-${time.getUTCMonth()}-${time.getUTCDate()} ${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}:${time.getUTCMilliseconds()}`;
    const local = `${time.getFullYear()}-${time.getMonth()}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}`;

    return this.timezone.toLowerCase() === 'utc' ? utc : local;
  }

  _formatReadableTime(time) {
    return new Date(time).toLocaleTimeString(this.locale, {
      hour12: false,
      timeZone: this.timezone,
    });
  }
}
customElements.define('rux-log', RuxLog);
