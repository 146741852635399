import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import template from './maintenance.html';
import css from './maintenance.css';
const utils = require('../common-utils.js');

/**
 * @polymer
 * @extends HTMLElement
 */
export class Maintenance extends PolymerElement {
  // Properties in HTML should be hyphenated, when referenced
  // in Javascript use camelCase and remove the hyphen
  static get properties() {
    return {
      job: {
        type: Object,
      },
      editMode: {
        type: Boolean,
        value: false,
      },
      potentialJobTypes: {
        type: Array,
        value: [1, 2, 3, 4, 5],
      },
      potentialJobTechnicians: {
        type: Array,
        value: ['Ahmet Ducat', 'Lara Pazzi', 'Cristofer Sandoval', 'Andie Spatzig'],
      },
      sampleConflicts: {
        type: Array,
        value: [
          {
            iron: 25234,
            groundStation: 'HULA',
            rev: 12141.3,
            equipmentString: 'ANT43 VAFB1 SFEP227CH1 ECEU6 WS402 USP177',
            state: 'normal',
            startTime: 1549044740,
            aos: 1549155840,
            los: 1549555620,
            endTime: 1549656720,
          },
          {
            iron: 25162,
            groundStation: 'COOK',
            rev: 15623.1,
            equipmentString: 'ANT92 SAFB1 SFEP217CH1 ECEU6 WS299 USP432',
            state: 'normal',
            startTime: 1559044740,
            aos: 1559155840,
            los: 1559555620,
            endTime: 1559656720,
          },
        ],
      },
    };
  }

  /* Define component-specific methods */

  populateLog() {
    for (let i = 0; i < 10; i++) {
      this.unshift('maintenanceLogData', {
        timestamp: new Date(),
        status: 'null',
        entry: this.logMessages[Math.floor(Math.random() * this.logMessages.length)],
      });
    }
  }

  exitJobDetails() {
    this.editMode = false;
    this.dispatchEvent(
        new CustomEvent('exitJobDetails', {
          detail: {},
          bubbles: true,
          composed: true,
        })
    );
  }
  saveJobDetails() {
    this.editMode = false;
    const notificationMessage = this.job.id
      ? `Maintenance Job ID #${this.job.id} details saved.`
      : 'New maintenance job requested.';
    this.dispatchEvent(
        new CustomEvent('saveJobDetails', {
          detail: {
            message: notificationMessage,
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  editJob() {
    this.editMode = true;
  }
  cancelEdit() {
    this.editMode = false;
  }

  constructor() {
    super();
    /* Register utility methods */
    this.is = utils.is;
    this.isPlural = utils.isPlural;
    this.capitalize = utils.capitalize;
    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
    this.formatDayOfYear = utils.formatDayOfYear;
    this.formatYear = utils.formatYear;
    this.mapJobType = utils.mapJobType;
    this.mapJobProgress = utils.mapJobProgress;
    this.logStatuses = utils.logStatuses;
    this.logMessages = utils.logMessages;
    this.maintenanceLogData = [];
    this.now = new Date();
  }

  connectedCallback() {
    super.connectedCallback();

    this.populateLog();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include='astro-css'>
            ${css}
          </style> 
          ${template}`,
    ]);
  }
}

customElements.define('maintenance-detail', Maintenance);
