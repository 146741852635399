/* eslint-disable no-unused-vars */
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import template from './rux-tab-panel.html';
import css from './rux-tab-panel.css';

/* eslint-enable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTabPanel extends PolymerElement {
  static get properties() {
    return {
      type: String,
    };
  }

  static get template() {
    return html([
      `
      <style include="astro-css">
        ${css}
      </style> 
      ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    // set the role to tab
    this.setAttribute('role', 'tabpanel');

    // hide the tab on load
    this.classList.add('hidden');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }
}

customElements.define('rux-tab-panel', RuxTabPanel);
