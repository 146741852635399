module.exports = {
  logStatuses: [
    'off',
    'standby',
    'normal',
    'caution',
    'serious',
    'critical',
  ],
  logMessages: [
    'Architecto temporibus iusto dolor quisquam',
    'Reiciendis similique earum qui quas corporis error et',
    'Necessitatibus magni corporis est nam asperiores est',
    'occaecati laudantium beatae',
    'Architecto et quasi. Rerum et quod iste eum aperiam voluptates vel. Blanditiis enim deserunt',
    'Dolorum expedita assumenda quia nihil omnis. Velit omnis fugit dolore laudantium quam dolor tempora asperiores corporis. Cupiditate quia ipsum',
  ],

  is(a, b) {
    return a === b;
  },

  isPlural(int) {
    return int === 1 ? '' : 's';
  },

  capitalize(val) {
    if (!val) return;
    return val.charAt(0).toUpperCase() + val.substring(1);
  },

  formatMachineTime(timestamp) {
    // assumes timestamp is a UTC Epoch
    const time = new Date(timestamp);

    return `
      ${time.getUTCFullYear()}-${time.getUTCMonth()}-${time.getUTCDate()} 
      ${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}:${time.getUTCMilliseconds()}
    `;
  },

  formatReadableTime(timestamp ) {
    // assumes timestamp is a UTC Epoch
    const time = new Date(timestamp);

    return new Date(time).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  },


  formatDayOfYear(timestamp) {
    const time = new Date(timestamp);

    function zeroFill(number, width) {
      width -= number.toString().length;
      if (width > 0) {
        return (
          new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
        );
      }
      return number + ''; // always return a string
    }

    const doy =
      (Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) -
        Date.UTC(time.getFullYear(), 0, 0)) /
      24 /
      60 /
      60 /
      1000;
    return zeroFill(doy, 3);
  },


  formatYear(timestamp) {
    const time = new Date(timestamp);
    return time.getUTCFullYear();
  },


  mapJobType(int) {
    switch (int) {
      case 1:
        return 'PMR 1';
      case 2:
        return 'PMR 2';
      case 3:
        return 'PMR 3';
      case 4:
        return 'PMR 4';
      case 5:
        return 'PMR 5';
      default:
        return 'Job Type not provided';
    }
  },

  mapJobProgress(int) {
    switch (int) {
      case 2:
        return 'Approved';
      case 3:
        return 'Started';
      case 4:
        return 'Stopped';
      case 5:
        return 'Online';
      default:
        return 'Submitted'; // also 1
    }
  },
};
