/* eslint-disable no-unused-vars */
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import template from './rux-tab.html';
import css from './rux-tab.css';
/* eslint-enable no-unused-vars */
/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTab extends PolymerElement {
  static get properties() {
    return {
      selected: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true,
      },
      type: {
        type: String,
      },
      style: {
        type: String,
      },
      dynamic: {
        type: Boolean,
        value: false,
      },
      targetTab: {
        type: String,
        reflectToAttribute: true,
        notify: true,
      },
      tabId: {
        type: String,
      },
    };
  }

  static get template() {
    return html([
      `
      <style include="astro-css">
        ${css}
      </style> 
      ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    // set the role to tab
    this.setAttribute('role', 'tab');

    if (this.parentElement.getAttributeNode('compact')) {
      this.setAttribute('compact', '');
    }

    if (this.parentElement.getAttributeNode('transparent')) {
      this.setAttribute('transparent', '');
    }

    if (this.parentElement.getAttributeNode('interior')) {
      this.setAttribute('interior', '');
    }

    // when a dynamic tab is added notify the rux-tabs.js so
    // it can do some magic and select the newly created tab
    // if (this.dynamic) {
    window.dispatchEvent(
        new CustomEvent('add-tab', {
          detail: {
            tab: this,
          },
        })
    );
    // }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  _closeTab(e) {
    // stop propogation so the tab doesn’t register a click
    // and select the tab
    e.stopPropagation();

    // dispatch the close tab event to the main app.
    // @todo in conjunction with code in app.js figure out a better way
    //        of identifying the tab and pass that infor up. Not sure the
    //        whole tab data structure is necessary
    window.dispatchEvent(
        new CustomEvent('close-tab', {
          detail: {
            tab: this,
            event: 'close',
          },
        })
    );
  }

  ready() {
    super.ready();
  }
}

customElements.define('rux-tab', RuxTab);
