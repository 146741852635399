import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

import template from './overview.html';
import css from './overview.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class Overview extends PolymerElement {
  static get properties() {
    return {
      equipment: {
        type: Object,
      },
    };
  }

  /* Define component-specific methods */

  getAllCategoryItems(category) {
    const flattenedArray = [];
    category.children.forEach((child) => {
      child.children.reduce((acc, val) => {
        acc.push(val);
        return acc;
      }, flattenedArray);
    });
    return flattenedArray;
  }

  getIconByCategory(category) {
    switch (category) {
      case 'comms':
        return 'monitoring:antenna-receive';
      case 'digital':
        return 'monitoring:processor-alt';
      case 'facilities':
        return 'monitoring:antenna-off';
      case 'rf':
        return 'monitoring:antenna';
      default:
        return '';
    }
  }

  sortAlphaByLabel(a, b) {
    return a.label > b.label ? 1 : -1;
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  _openTab(event) {
    window.dispatchEvent(
        new CustomEvent('select-tree-item', {
          detail: event.model.item,
        })
    );
  }

  static get template() {
    return html([
      `<style include='astro-css'>
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('equipment-overview', Overview);
